import {Button, type ButtonBaseProps} from '@primer/react'
import {CodeIcon} from '@primer/octicons-react'
import type {Model} from '@github-ui/marketplace-common'
import {sendEvent, type SendEventContext} from '@github-ui/hydro-analytics'
import {useUser} from '@github-ui/use-user'
import {testIdProps} from '@github-ui/test-id-props'
import {GettingStartedButtonClicked} from '../utils/playground-types'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'

interface GetApiKeyButtonProps
  extends Pick<ButtonBaseProps, 'onClick' | 'variant' | 'block' | 'tabIndex' | 'className'> {
  model: Model
}

export function GetApiKeyButton({model, ...buttonProps}: GetApiKeyButtonProps) {
  const {currentUser} = useUser()
  const abTestingEnabled = useFeatureFlag('github_models_ab_testing')
  const useNewLabelText = useFeatureFlag('github_models_get_api_key_button_experiment')
  const label = abTestingEnabled && useNewLabelText ? 'Use this model' : 'Get API key'
  const onClick = buttonProps.onClick
  return (
    <Button
      leadingVisual={CodeIcon}
      aria-label={label}
      {...buttonProps}
      onClick={evt => {
        if (onClick) onClick(evt)
        const payload: SendEventContext = {registry: model.registry, model: model.name, publisher: model.publisher}
        if (abTestingEnabled) {
          payload.label = label
          payload.analyticsTrackingId = currentUser?.analyticsTrackingId
        }
        sendEvent(GettingStartedButtonClicked, payload)
      }}
      {...testIdProps('get-api-key-button')}
    >
      <span className="hide-sm">{label}</span>
    </Button>
  )
}

try{ GetApiKeyButton.displayName ||= 'GetApiKeyButton' } catch {}