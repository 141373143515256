import {GitHubAvatar} from '@github-ui/github-avatar'
import {Link} from '@primer/react'
import {TransparencyDataTable} from './TransparencyDataTable'
import type {AppListing} from '@github-ui/marketplace-common'

export type PublisherInfoProps = {
  app: AppListing
  verifiedProfileDomains: string[]
}

export function PublisherInfo({app, verifiedProfileDomains}: PublisherInfoProps) {
  const {
    ownerSafeProfileName,
    ownerImage,
    ownerType,
    traderAddress,
    traderId,
    traderIdType,
    traderSelfCertification,
    statusUrl,
    tosUrl,
    privacyPolicyUrl,
    supportUrl,
    supportEmail,
    twoFactorRequirementEnabled,
  } = app

  const items = [
    {
      label: 'Developer',
      value: ownerSafeProfileName && (
        <>
          {ownerImage && (
            <GitHubAvatar
              src={ownerImage}
              alt={ownerSafeProfileName}
              size={16}
              square={ownerType === 'User' ? false : true}
              className="mr-1"
            />
          )}
          {ownerSafeProfileName}
        </>
      ),
    },
    {
      label: 'Company domain',
      value: verifiedProfileDomains.length > 0 && (
        <>
          {verifiedProfileDomains.map(domain => (
            <Link key={domain} href={`https://${domain}`} rel="nofollow" className="d-block">
              {domain}
            </Link>
          ))}
        </>
      ),
    },
    {label: 'Business address', value: traderAddress && <>{traderAddress}</>},
    {
      label: 'Business ID',
      value: traderId && traderIdType && (
        <>
          {traderIdType}: {traderId}
        </>
      ),
    },
    {
      label: 'EU Trader',
      value:
        traderSelfCertification &&
        (traderSelfCertification === 'trader' ? (
          <>Yes - Classifies as a trader in the European Union</>
        ) : (
          <>No - Does not classify as a trader in the European Union</>
        )),
    },
    {
      label: 'Status page',
      value: statusUrl && (
        <Link href={statusUrl} rel="nofollow">
          {statusUrl}
        </Link>
      ),
    },
    {
      label: 'Terms of service',
      value: tosUrl && (
        <Link href={tosUrl} rel="nofollow">
          {tosUrl}
        </Link>
      ),
    },
    {
      label: 'Privacy policy',
      value: privacyPolicyUrl && (
        <Link href={privacyPolicyUrl} rel="nofollow">
          {privacyPolicyUrl}
        </Link>
      ),
    },
    {
      label: 'Support URL',
      value: supportUrl && (
        <Link href={supportUrl} rel="nofollow">
          {supportUrl}
        </Link>
      ),
    },
    {
      label: 'Support email',
      value: supportEmail && <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>,
    },
    {
      label: 'Publisher 2FA Required',
      value:
        ownerType === 'User' ? (
          <>Not applicable - Individual publisher</>
        ) : (
          ownerType === 'Organization' &&
          (twoFactorRequirementEnabled ? (
            <>Yes - Publisher organization requires 2FA</>
          ) : (
            <>No - Organization does not require 2FA</>
          ))
        ),
    },
  ]

  return (
    <div data-testid="publisher-info">
      <TransparencyDataTable items={items} />
    </div>
  )
}

try{ PublisherInfo.displayName ||= 'PublisherInfo' } catch {}