import {Heading, Text, Link} from '@primer/react'
import {Revealer} from './Revealer'
import {PublisherInfo} from './transparency/PublisherInfo'
import {PermissionsInfo} from './transparency/PermissionsInfo'
import {ScopesInfo} from './transparency/ScopesInfo'
import {SecurityAndComplianceInfo} from './transparency/SecurityAndComplianceInfo'
import type {AppListing} from '@github-ui/marketplace-common'
import type {PermissionsData} from '../../types'

export type TransparencySectionProps = {
  app: AppListing
  verifiedProfileDomains: string[]
  permissionsData: PermissionsData[]
}

export function TransparencySection({app, verifiedProfileDomains, permissionsData}: TransparencySectionProps) {
  const {
    aiRiskLevel,
    llmsInUse,
    thirdPartyServices,
    repositoryVisibility,
    repositoryUrl,
    transparencyDisclosure,
    copilotApp,
  } = app
  const securityAndComplianceFields = {
    aiRiskLevel,
    llmsInUse,
    thirdPartyServices,
    repositoryVisibility,
    repositoryUrl,
    transparencyDisclosure,
    copilotApp,
  }
  const shouldRenderSecurityAndCompliance = Object.values(securityAndComplianceFields).some(value => value)

  const infoSections = [
    {
      title: '1. Publisher',
      content: <PublisherInfo app={app} verifiedProfileDomains={verifiedProfileDomains} />,
      defaultOpen: true,
    },
    {
      title: '2. Permissions',
      content:
        app.listableType === 'Integration' ? <PermissionsInfo permissionsData={permissionsData} /> : <ScopesInfo />,
      defaultOpen: false,
    },
    {
      title: '3. Security & Compliance',
      content: shouldRenderSecurityAndCompliance && <SecurityAndComplianceInfo {...securityAndComplianceFields} />,
      defaultOpen: false,
    },
  ]

  return (
    <div data-testid="transparency-section">
      <Heading as="h2" variant="medium" className="pb-3">
        Transparency and security
      </Heading>
      {infoSections.map(
        section =>
          section.content && (
            <Revealer key={section.title} title={section.title} defaultOpen={section.defaultOpen}>
              {section.content}
            </Revealer>
          ),
      )}
      <div className="border-top color-border-muted pt-3">
        <Text size="small" className="fgColor-muted">
          For more information on the terms of service on the GitHub Marketplace, please visit the{' '}
          <Link
            inline
            href="https://docs.github.com/en/site-policy/github-terms/github-marketplace-developer-agreement"
          >
            Marketplace Developer Agreement
          </Link>
          .
        </Text>
      </div>
    </div>
  )
}

try{ TransparencySection.displayName ||= 'TransparencySection' } catch {}