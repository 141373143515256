import {testIdProps} from '@github-ui/test-id-props'
import React, {type FormEvent, useEffect, useState} from 'react'
import {Checkbox, FormControl, Radio, RadioGroup, TextInput} from '@primer/react'
import {Banner, Dialog} from '@primer/react/experimental'
import {useClickAnalytics} from '@github-ui/use-analytics'

import type {MessageContent, Preset} from '../../../../types'
import {Panel} from '../../../../utils/playground-manager'
import {usePlaygroundState} from '../../../../contexts/PlaygroundStateContext'
import {createPreset, updatePreset} from '../../../../utils/presets'

export const NAME_MAX_SIZE = 100
export const DESCRIPTION_MAX_SIZE = 255
export const INPUT_VALUE = {
  none: 'none',
  chatInput: 'chatInput',
  firstMessage: 'firstMessage',
}

const ifEmptyUndefined = (value: string) => (value.trim().length === 0 ? undefined : value.trim())

const getText = (messageContent: MessageContent | string = ''): string | undefined => {
  if (typeof messageContent === 'string') return ifEmptyUndefined(messageContent)

  return ifEmptyUndefined(
    messageContent
      .filter(m => m.type === 'text')
      .map(m => m.text)
      .join('\n'),
  )
}

export const getChatPrompt = (inputValue: string, chatInput?: string, firstMessage?: string): string | undefined => {
  switch (inputValue) {
    case INPUT_VALUE.chatInput:
      return chatInput
    case INPUT_VALUE.firstMessage:
      return firstMessage
    case INPUT_VALUE.none:
    default:
      return undefined
  }
}

export interface PresetDialogProps {
  onClose: () => void
  onSuccess: (urlIdentifier: string) => void
  selectedPreset: Preset
  action: 'create' | 'update'
}

export function PresetDialog({onClose, onSuccess, selectedPreset, action}: PresetDialogProps) {
  const {sendClickAnalyticsEvent} = useClickAnalytics()
  const playgroundState = usePlaygroundState()
  const mainModel = playgroundState.models[Panel.Main]

  // Endpoint errors
  const [errors, setErrors] = useState<{
    error?: string
    name?: string[]
    url_identifier?: string
  } | null>(null)
  // Form errors
  const [nameError, setNameError] = useState<string | null>(null)
  const [inputValue, setInputValue] = useState<string>(INPUT_VALUE.none)

  const chatInput = getText(mainModel?.chatInput)
  const firstMessage = getText(mainModel?.messages[0]?.message)

  const getParameters = (selected?: string): {system_prompt: string; chat_prompt: string} | {system_prompt: string} => {
    const chat_prompt = getChatPrompt(selected || inputValue, chatInput, firstMessage)
    const system_prompt = mainModel?.systemPrompt ?? ''
    return chat_prompt ? {system_prompt, chat_prompt} : {system_prompt}
  }

  const isCreate = action === 'create'
  // Preset to save
  const [presetData, setPresetData] = useState<Preset>(() => ({
    name: isCreate ? '' : selectedPreset.name,
    private: selectedPreset.private,
    parameters: getParameters(),
    urlIdentifier: selectedPreset.urlIdentifier,
  }))

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const {name, type, value, checked} = e.currentTarget

    // For the checkboxes, we want the value sent to the backend to be the opposite of "Enable sharing"
    setPresetData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? (name === 'private' ? !checked : checked) : value,
    }))
  }

  const handleChatInputChange = (selected: string | null) => {
    setInputValue(selected || INPUT_VALUE.none)
    setPresetData(prevState => ({
      ...prevState,
      parameters: getParameters(selected || undefined),
    }))
  }

  const nameInputRef = React.useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus()
    }

    if (errors && errors.url_identifier) {
      setNameError(`Name ${errors.url_identifier}`)
      nameInputRef.current?.focus()
    }

    if (errors && errors.name) {
      setNameError(`Name ${errors.name.join(', ')}`)
      nameInputRef.current?.focus()
    }
  }, [errors])

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    // Validation
    const {name} = presetData
    const sizeMsg = (param: string, size: number) => `${param} cannot be longer than ${size} characters`

    setNameError(null)

    const noName = name.trim().length === 0
    const nameTooLong = name.length > NAME_MAX_SIZE

    if (noName) {
      setNameError('Name is required')
      nameInputRef.current?.focus()
    }
    if (nameTooLong) {
      setNameError(sizeMsg('Name', NAME_MAX_SIZE))
      nameInputRef.current?.focus()
    }

    if (noName || nameTooLong) {
      return
    }

    // Save preset
    try {
      const {urlIdentifier, ...savePresetData} = presetData

      const res = isCreate
        ? await createPreset({preset: savePresetData})
        : await updatePreset({urlIdentifier, preset: savePresetData})

      if (res.ok) {
        const resPreset = await res.json()
        onSuccess(resPreset.urlIdentifier)
        sendClickAnalyticsEvent({
          category: 'github_models_playground',
          action: 'click_to_save_preset',
          label: 'ref_cta:save_preset_dialog;ref_loc:presets_dialog',
        })
        onClose()
      } else {
        const body = await res.json()
        setErrors(body.error)
      }
    } catch {
      setErrors({error: `Failed to ${action} preset: ${presetData.name}`})
    }
  }

  return (
    <Dialog
      title={
        <div {...testIdProps('save-preset-dialog')} className="capitalize">{`${
          isCreate ? 'Create' : 'Update'
        } prompt`}</div>
      }
      width="large"
      onClose={() => {
        onClose()
        sendClickAnalyticsEvent({
          category: 'github_models_playground',
          action: 'click_to_close_preset_dialog',
          label: 'ref_cta:close_preset_dialog;ref_loc:presets_dialog',
        })
      }}
      footerButtons={[
        {
          buttonType: 'default',
          content: 'Cancel',
          onClick: onClose,
          form: 'preset-dialog-form',
        },
        {
          buttonType: 'primary',
          content: isCreate ? 'Save prompt' : 'Update prompt',
          form: 'preset-dialog-form',
          type: 'submit',
        },
      ]}
    >
      {errors?.error && (
        <Banner
          hideTitle
          variant="critical"
          title="Something went wrong"
          description={errors.error}
          className="mb-3"
          {...testIdProps('generic-error-banner')}
        />
      )}

      <form onSubmit={onSubmit} id="preset-dialog-form">
        <FormControl id="preset-name-input" className="mb-3" required>
          <FormControl.Label>Name</FormControl.Label>
          <TextInput
            name="name"
            onChange={handleChange}
            placeholder=""
            value={presetData.name}
            validationStatus={nameError ? 'error' : undefined}
            ref={nameInputRef}
            className="width-full"
          />
          {nameError ? <FormControl.Validation variant="error">{nameError}</FormControl.Validation> : null}
        </FormControl>

        {chatInput || firstMessage ? (
          <RadioGroup
            id="preset-chat-input-radio"
            className="mb-3"
            name="chatInputSelector"
            onChange={handleChatInputChange}
          >
            <RadioGroup.Label>Chat input</RadioGroup.Label>
            <RadioGroup.Caption className="text-small">
              This will allow you to provide a chat message to the model to help it generate a response.
            </RadioGroup.Caption>
            <FormControl className="pl-4">
              <Radio value={INPUT_VALUE.none} checked={inputValue === INPUT_VALUE.none} />
              <FormControl.Label>None</FormControl.Label>
            </FormControl>
            {chatInput && (
              <FormControl className="pl-4">
                <Radio value={INPUT_VALUE.chatInput} checked={inputValue === INPUT_VALUE.chatInput} />
                <FormControl.Label>Current chat input</FormControl.Label>
                <FormControl.Caption>
                  <div className="width-full my-2 p-2 border rounded-1 text-small">{chatInput}</div>
                </FormControl.Caption>
              </FormControl>
            )}
            {firstMessage && (
              <FormControl className="pl-4">
                <Radio value={INPUT_VALUE.firstMessage} checked={inputValue === INPUT_VALUE.firstMessage} />
                <FormControl.Label>First chat message</FormControl.Label>
                <FormControl.Caption>
                  <div className="width-full my-2 p-2 border rounded-1 text-small">{firstMessage}</div>
                </FormControl.Caption>
              </FormControl>
            )}
          </RadioGroup>
        ) : null}

        <FormControl id="preset-private-checkbox">
          <Checkbox name="private" checked={!presetData.private} onChange={handleChange} />
          <FormControl.Label>Enable sharing</FormControl.Label>
          <FormControl.Caption>
            Anyone with the URL will be able to view and use this prompt, but not edit. Prompts are private by default.
          </FormControl.Caption>
        </FormControl>
      </form>
    </Dialog>
  )
}

try{ PresetDialog.displayName ||= 'PresetDialog' } catch {}