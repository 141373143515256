import {useCallback, useState} from 'react'
import type {ImprovePromptDialogState, ShowModelPayload} from '../../../types'
import {ImprovePromptDialog} from './ImprovePromptDialog'
import {ImprovePromptConfirmationDialog} from './ImprovePromptConfirmationDialog'
import {sendEvent} from '@github-ui/hydro-analytics'
import {
  CancelImprovedPromptClicked,
  ImprovePromptClicked,
  UseImprovedPromptClicked,
} from '../../../utils/playground-types'
import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {Button} from '@primer/react'

export type ImprovePromptProps = {
  systemPrompt: string
  handleUpdatePrompt: (improvedPrompt: string) => void
}

export function ImprovePrompt({systemPrompt, handleUpdatePrompt}: ImprovePromptProps) {
  const {improvedSysPromptModel} = useRoutePayload<ShowModelPayload>()

  const [dialogState, setDialogState] = useState<ImprovePromptDialogState>('closed')

  const [promptSuggestionText, setPromptSuggestionText] = useState('')
  const [improvedPromptText, setImprovedPromptText] = useState('')

  const onOpen = useCallback(() => {
    setDialogState('suggest')
    sendEvent(ImprovePromptClicked)
  }, [])

  const onClose = useCallback(() => {
    setDialogState('closed')
  }, [])

  const onCloseConfirmationDialog = useCallback(() => {
    setDialogState('closed')
    sendEvent(CancelImprovedPromptClicked)
  }, [])

  const handleUpdate = useCallback(() => {
    handleUpdatePrompt(improvedPromptText)
    setPromptSuggestionText('')
    setDialogState('closed')
    sendEvent(UseImprovedPromptClicked)
  }, [handleUpdatePrompt, improvedPromptText])

  if (!improvedSysPromptModel) {
    return null
  }

  return (
    <>
      <Button onClick={onOpen} variant="invisible" size="small">
        <div className="d-flex flex-items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width="14"
            height="14"
            aria-label="Sparkles fill icon"
            fill="var(--fgColor-muted, var(--color-muted-fg))"
          >
            <path d="M9.6 2.279a.426.426 0 0 1 .8 0l.407 1.112a6.386 6.386 0 0 0 3.802 3.802l1.112.407a.426.426 0 0 1 0 .8l-1.112.407a6.386 6.386 0 0 0-3.802 3.802l-.407 1.112a.426.426 0 0 1-.8 0l-.407-1.112a6.386 6.386 0 0 0-3.802-3.802L4.279 8.4a.426.426 0 0 1 0-.8l1.112-.407a6.386 6.386 0 0 0 3.802-3.802L9.6 2.279Zm-4.267 8.837a.178.178 0 0 1 .334 0l.169.464a2.662 2.662 0 0 0 1.584 1.584l.464.169a.178.178 0 0 1 0 .334l-.464.169a2.662 2.662 0 0 0-1.584 1.584l-.169.464a.178.178 0 0 1-.334 0l-.169-.464a2.662 2.662 0 0 0-1.584-1.584l-.464-.169a.178.178 0 0 1 0-.334l.464-.169a2.662 2.662 0 0 0 1.584-1.584l.169-.464ZM2.8.14a.213.213 0 0 1 .4 0l.203.556a3.2 3.2 0 0 0 1.901 1.901l.556.203a.213.213 0 0 1 0 .4l-.556.203a3.2 3.2 0 0 0-1.901 1.901L3.2 5.86a.213.213 0 0 1-.4 0l-.203-.556A3.2 3.2 0 0 0 .696 3.403L.14 3.2a.213.213 0 0 1 0-.4l.556-.203A3.2 3.2 0 0 0 2.597.696L2.8.14Z" />
          </svg>
          <span>Improve prompt</span>
        </div>
      </Button>
      {dialogState === 'suggest' && (
        <ImprovePromptDialog
          onClose={onClose}
          setDialogState={setDialogState}
          currentPrompt={systemPrompt}
          setCurrentPrompt={handleUpdatePrompt}
          promptSuggestionText={promptSuggestionText}
          setPromptSuggestionText={setPromptSuggestionText}
        />
      )}
      {dialogState === 'confirm' && (
        <ImprovePromptConfirmationDialog
          onClose={onCloseConfirmationDialog}
          handleUpdateSystemPrompt={handleUpdate}
          promptSuggestionText={promptSuggestionText}
          currentPrompt={systemPrompt}
          setImprovedPromptText={setImprovedPromptText}
          improvedPromptText={improvedPromptText}
          improvedSysPromptModel={improvedSysPromptModel}
          setDialogState={setDialogState}
        />
      )}
    </>
  )
}

try{ ImprovePrompt.displayName ||= 'ImprovePrompt' } catch {}