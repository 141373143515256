import {Dialog} from '@primer/react/experimental'
import styles from './GettingStartedDialog.module.css'
import {TabGetKey} from './tabs/GettingStartedDialogTabGetKey'
import {useDocsProvider} from '../../contexts/ModelDocsContext'

export interface GettingStartedDialogTabbedProps {
  onClose: () => void
  modelName: string
}

export function GettingStartedDialogTabbed({onClose, modelName}: GettingStartedDialogTabbedProps) {
  return (
    <Dialog
      title={`Use ${modelName}`}
      position={{narrow: 'fullscreen', regular: 'right'}}
      onClose={onClose}
      className={styles.gettingStartedDialog}
      renderBody={() => <DialogBody />}
    />
  )
}

function DialogBody() {
  const {modelDocs} = useDocsProvider()

  return (
    <div className="p-3">
      <div className="mt-3">
        <TabGetKey modelDocs={modelDocs} />
      </div>
    </div>
  )
}

try{ GettingStartedDialogTabbed.displayName ||= 'GettingStartedDialogTabbed' } catch {}
try{ DialogBody.displayName ||= 'DialogBody' } catch {}