import type React from 'react'
import {forwardRef, useMemo, useState} from 'react'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import type {PlanInfo, Plan} from '../../../types'
import type {AppListing} from '@github-ui/marketplace-common'
import {AccountSelector} from './AccountSelector'
import {Link, TextInput, Stack, FormControl} from '@primer/react'
import {useCSRFToken} from '@github-ui/use-csrf-token'
import {HeaderInstallButton, InstallButton} from './InstallButton'
import {InstallHelpText} from './InstallHelpText'

type Props = {
  planInfo: PlanInfo
  listing: AppListing
  plan: Plan
  standaloneButton?: boolean
  selectedAccount?: string
  onAccountSelect: (account: string) => void
}

export const PlanForm = forwardRef(function InternalPlanForm(
  {planInfo, listing, plan, standaloneButton, selectedAccount, onAccountSelect}: Props,
  ref?: React.ForwardedRef<HTMLFormElement>,
) {
  const [seatQuantity, setSeatQuantity] = useState(planInfo.orderPreview?.quantity || 1)
  const marketplaceFreeInstallFlag = useFeatureFlag('marketplace_free_install')
  const marketplacePurchaseReconciliationFlag = useFeatureFlag('marketplace_purchase_reconciliation')
  const skipOrderReview = useMemo(() => {
    // For Copilot Extensions, we skip the order review stage since they're free
    if (marketplaceFreeInstallFlag && listing.copilotApp && !plan.isPaid) {
      return true
    }

    // For direct billing plans, we skip the order review stage since payment is setup outside of GitHub
    return plan.directBilling
  }, [listing.copilotApp, marketplaceFreeInstallFlag, plan.directBilling, plan.isPaid])

  const installedForSelectedAccount = useMemo(() => {
    if (!selectedAccount) return false
    if (selectedAccount === planInfo.currentUser?.displayLogin) {
      return planInfo.installedForViewer
    } else {
      return planInfo.organizations.find(org => org.displayLogin === selectedAccount)?.installedForOrg
    }
  }, [planInfo.organizations, selectedAccount, planInfo.installedForViewer, planInfo.currentUser?.displayLogin])

  const purchasedForAccount = selectedAccount && planInfo.planIdByLogin[selectedAccount] === plan.id
  const purchasedButNotInstalled = purchasedForAccount && !installedForSelectedAccount
  const canReinstall = marketplacePurchaseReconciliationFlag && purchasedButNotInstalled
  const alreadyPurchased = skipOrderReview && selectedAccount && planInfo.planIdByLogin[selectedAccount]

  const authToken = useCSRFToken(
    `/marketplace/${listing.slug}/order/${plan.id}${alreadyPurchased || canReinstall ? '/upgrade' : ''}`,
    'post',
  )

  return (
    <form
      ref={ref}
      method={skipOrderReview || canReinstall ? 'POST' : 'GET'}
      action={`/marketplace/${listing.slug}/order/${plan.id}${alreadyPurchased || canReinstall ? '/upgrade' : ''}`}
      data-testid="plan-form"
      className="width-full"
      id="plan-form"
    >
      <input type="hidden" name="quantity" id="quantity" value="1" />
      {(skipOrderReview || canReinstall) && (
        // eslint-disable-next-line github/authenticity-token
        <input type="hidden" name="authenticity_token" value={authToken} data-testid="csrf-token" />
      )}
      {skipOrderReview && (
        <>
          {planInfo.canSignEndUserAgreement && planInfo.endUserAgreement && (
            <>
              <input type="hidden" name="marketplace_listing_id" id="marketplace_listing_id" value={listing.id} />
              <input
                type="hidden"
                name="marketplace_agreement_id"
                id="marketplace_agreement_id"
                value={planInfo.endUserAgreement.id}
              />
            </>
          )}
        </>
      )}

      {standaloneButton ? (
        <>
          <input type="hidden" name="account" id="account" value={selectedAccount} data-testid="standalone-account" />
          <HeaderInstallButton planInfo={planInfo} plan={plan} listing={listing} account={selectedAccount} />
        </>
      ) : (
        <Stack gap="normal" className={plan.isPaid && !plan.perUnit ? '' : 'pt-3'}>
          {plan.isPaid && (
            <>
              {plan.perUnit && (
                <FormControl id="quantity">
                  <FormControl.Label htmlFor="quantity" className="text-normal" as="label">
                    {`Number of ${plan.unitName}s`}
                  </FormControl.Label>
                  <TextInput
                    type="number"
                    value={seatQuantity}
                    name="quantity"
                    min="0"
                    max="100000"
                    sx={{width: '6rem'}}
                    onChange={e => setSeatQuantity(parseInt(e.target.value))}
                  />
                </FormControl>
              )}
              <div className={'border-bottom border-top py-2 d-flex flex-items-baseline flex-justify-between'}>
                <div className="d-flex flex-items-baseline">
                  <span className="f2 mr-2" data-testid="plan-form-price">
                    {plan.price}
                  </span>
                  <span className="text color-fg-muted">
                    {plan.perUnit && <span> {`/ ${plan.unitName}`}</span>}
                    <span>{planInfo.isUserBilledMonthly ? ' / month' : ' / year'}</span>
                  </span>
                </div>
              </div>
            </>
          )}

          {listing.copilotApp && (
            <p className="mb-0">
              Copilot extensions are currently in{' '}
              <Link href="https://docs.github.com/en/site-policy/github-terms/github-pre-release-license-terms" inline>
                preview
              </Link>{' '}
              for all Copilot users. Supported organizations are indicated.
            </p>
          )}

          <AccountSelector planInfo={planInfo} listing={listing} value={selectedAccount} onChange={onAccountSelect} />

          {!ref && (
            <div>
              <InstallButton planInfo={planInfo} plan={plan} listing={listing} account={selectedAccount} />
              <InstallHelpText planInfo={planInfo} plan={plan} listing={listing} />
            </div>
          )}
        </Stack>
      )}
    </form>
  )
})

try{ PlanForm.displayName ||= 'PlanForm' } catch {}