import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {testIdProps} from '@github-ui/test-id-props'
import type {ShowModelPayload} from '../../../types'
import {SafeHTMLBox} from '@github-ui/safe-html'

export function Evaluation() {
  const {modelEvaluation} = useRoutePayload<ShowModelPayload>()

  return (
    <>
      {modelEvaluation && (
        <SafeHTMLBox data-hpc html={modelEvaluation} className="p-3" {...testIdProps('evaluation-content')} />
      )}
    </>
  )
}

try{ Evaluation.displayName ||= 'Evaluation' } catch {}