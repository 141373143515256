import type {SafeHTMLString} from '@github-ui/safe-html'
import type {ModelDocs} from '../../../../../types'
import MarkdownContent from '../MarkdownContent'

export function TabGetKey({modelDocs}: {modelDocs?: ModelDocs}) {
  return (
    <div>
      <MarkdownContent payload={modelDocs?.getting_started as SafeHTMLString} />
    </div>
  )
}

try{ TabGetKey.displayName ||= 'TabGetKey' } catch {}