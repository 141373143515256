import {Button, useResponsiveValue} from '@primer/react'
import type {PlanInfo} from '../../types'
import checkCanInstall from '../../utilities/check-can-install'

type ActionButtonProps = {
  planInfo: PlanInfo
  type?: 'submit'
  children?: React.ReactNode
  disabled?: boolean
  'data-testid'?: string
  'data-octo-click'?: string
  'data-octo-dimensions'?: string
} & (
  | {
      as: 'button'
      onClick?: () => void
    }
  | {as: 'a'; href?: string}
)

/**
 * ActionButton is a reusable component for rendering the current app listings call to action.
 * This can either be a button or a link, depending on the `as` prop.
 * If the `as` prop is a link, the href prop is allowed, if the `as` prop is a button,
 * the onClick prop is allowed.
 *
 * There is some logic for the default text of the component, but this can be overridden
 * (for example by the HeaderInstallButton component) by passing in the children prop.
 */
export default function ActionButton(props: ActionButtonProps) {
  const isMobile = useResponsiveValue({narrow: true}, false) as boolean
  const isScrollButton = props.as === 'a'
  const href = isScrollButton ? props.href : undefined
  const onClick = !isScrollButton ? props.onClick : undefined
  const classes = `width-full ${isScrollButton ? 'js-smoothscroll-anchor' : ''}`
  const disabled = !checkCanInstall(props.planInfo).canInstall || props.disabled

  return (
    <Button
      as={props.as}
      variant="primary"
      className={classes}
      href={href}
      disabled={disabled}
      aria-describedby={disabled ? 'install-unavailable-reason' : undefined}
      size={isMobile ? 'medium' : 'large'}
      type={props.type}
      onClick={onClick}
      data-testid={props['data-testid']}
      data-octo-click={props['data-octo-click']}
      data-octo-dimensions={props['data-octo-dimensions']}
    >
      {props.children
        ? props.children
        : props.planInfo.viewerHasPurchased || props.planInfo.anyOrgsPurchased
          ? 'Add'
          : props.planInfo.plans.some(plan => plan.hasFreeTrial)
            ? 'Set up a free trial'
            : 'Add'}
    </Button>
  )
}

try{ ActionButton.displayName ||= 'ActionButton' } catch {}