import {createContext, useContext, useMemo, type PropsWithChildren} from 'react'
import type {ModelDocs} from '../../../types'

interface ModelDocsProviderProps extends PropsWithChildren {
  modelDocs: ModelDocs
}

interface ModelDocsContextProps extends PropsWithChildren {
  modelDocs?: ModelDocs
}

const ModelDocsContext = createContext<ModelDocsContextProps>({
  modelDocs: undefined,
})

export const DocsProvider = ({children, modelDocs}: ModelDocsProviderProps) => {
  const value = useMemo(() => ({modelDocs}) satisfies ModelDocsContextProps, [modelDocs])
  return <ModelDocsContext.Provider value={value}>{children}</ModelDocsContext.Provider>
}

export const useDocsProvider = () => {
  return useContext(ModelDocsContext)
}

try{ ModelDocsContext.displayName ||= 'ModelDocsContext' } catch {}
try{ DocsProvider.displayName ||= 'DocsProvider' } catch {}