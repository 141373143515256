import {isFeatureEnabled} from '@github-ui/feature-flags'

/**
 * IMPORTANT NOTICE: Feature flags added here must also be added to the `lib/github/client_side_feature_flags.rb` file
 * or they will not be available to check in this file!
 */

class CopilotFeatureFlags {
  /**
   * Returns a boolean indicating whether the Copilot conversational UX embedding update feature is enabled.
   * @returns {boolean} Whether the feature is enabled.
   */
  public get embedding() {
    return isFeatureEnabled('copilot_conversational_ux_embedding_update')
  }

  public get unstickyReferences() {
    return isFeatureEnabled('copilot_conversational_ux_history_refs')
  }

  public get implicitContext() {
    return isFeatureEnabled('COPILOT_IMPLICIT_CONTEXT')
  }

  public get customInstructions() {
    return isFeatureEnabled('copilot_chat_custom_instructions')
  }

  // Similar to customInstructions above, but they are for personal use. The customInstructions in the flag above is for
  // repo custom instructions.
  public get personalInstructions() {
    return isFeatureEnabled('copilot_personal_instructions')
  }

  public get reactMarkdown() {
    return isFeatureEnabled('copilot_react_markdown')
  }

  public get dotcomUserServerTokens() {
    return isFeatureEnabled('copilot_chat_dotcom_user_server_tokens')
  }

  public get immersiveFilePreview() {
    return isFeatureEnabled('copilot_immersive_file_preview')
  }

  public get immersiveIssuePreview() {
    return isFeatureEnabled('copilot_immersive_issue_preview')
  }

  public get bringYourOwnKey() {
    return isFeatureEnabled('copilot_byok')
  }

  /**
   * When enabled, the interview survey dialog replaces the 'Give feedback' dialog in copilot chat.
   * @returns {boolean} Whether the feature is enabled.
   */
  public get copilotChatInterviewSurvey() {
    return isFeatureEnabled('copilot_chat_interview_survey')
  }

  public get newImmersiveReferencesUI() {
    return isFeatureEnabled('copilot_new_immersive_references_ui')
  }

  public get repoCustomInstructions() {
    return isFeatureEnabled('copilot_chat_repo_custom_instructions')
  }

  public get repoCustomInstructionsPreview() {
    return isFeatureEnabled('copilot_chat_repo_custom_instructions_preview')
  }

  public get copilotFreeLimitedUser() {
    return isFeatureEnabled('copilot_free_limited_user')
  }

  public get sharedTopicIndicator() {
    return isFeatureEnabled('copilot_chat_shared_topic_indicator')
  }

  public get sharedSSOBanner() {
    return isFeatureEnabled('copilot_chat_shared_repo_sso_banner')
  }

  public get attachImagesImmersive() {
    return isFeatureEnabled('copilot_chat_attach_images')
  }

  public get copilotChatRetryOnError() {
    return isFeatureEnabled('copilot_chat_retry_on_error')
  }

  public get immersiveSubthreading() {
    return isFeatureEnabled('copilot_chat_immersive_subthreading')
  }

  public get copilotChatPersistSubmittedInput() {
    return isFeatureEnabled('copilot_chat_persist_submitted_input')
  }

  public get customCopilots() {
    return isFeatureEnabled('copilot_custom_copilots')
  }

  public get bypassIndexingQuota() {
    return isFeatureEnabled('bypass_copilot_indexing_quota')
  }

  public get reduceTelemetry() {
    return isFeatureEnabled('copilot_dotcom_chat_reduce_telemetry')
  }

  public get editorUpsells() {
    return isFeatureEnabled('copilot_editor_upsells')
  }

  public get shareConversation() {
    return isFeatureEnabled('copilot_share_conversation')
  }

  public get clientDOMSkills() {
    return isFeatureEnabled('copilot_client_dom_skills')
  }

  public get newMarkdownRenderer() {
    return isFeatureEnabled('copilot_new_markdown_renderer')
  }

  public get sharedChatInput() {
    return isFeatureEnabled('copilot_chat_shared_chat_input')
  }

  public get dotcomChatClientSideSkills() {
    return isFeatureEnabled('dotcom_chat_client_side_skills')
  }

  public get ambientErrorBanner() {
    return isFeatureEnabled('copilot_chat_ambient_error_banner')
  }

  public get attachFolderReference() {
    return isFeatureEnabled('copilot_attach_folder_reference')
  }

  public get taskOrientedAssistive() {
    return isFeatureEnabled('copilot_task_oriented_assistive')
  }

  public get copilotChatThreadSwitchingFix() {
    return isFeatureEnabled('copilot_chat_thread_switching_fix')
  }

  public get plugins() {
    return isFeatureEnabled('copilot_immersive_plugin_system')
  }

  public get pipesPlugin() {
    return isFeatureEnabled('copilot_pipes')
  }

  public get copilotChatOpeningThreadSwitch() {
    return isFeatureEnabled('copilot_chat_opening_thread_switch')
  }

  public get copilotReadSharedConversation() {
    return isFeatureEnabled('copilot_read_shared_conversation')
  }

  public get copilotDuplicateThread() {
    return isFeatureEnabled('copilot_duplicate_thread')
  }

  public get copilotUIRefs() {
    return isFeatureEnabled('copilot_ui_refs')
  }
}

export const copilotFeatureFlags = new CopilotFeatureFlags()
export type {CopilotFeatureFlags}
