import type {PropsWithChildren} from 'react'
import {FilterProvider} from './FilterContext'
import {SearchResultsProvider} from './SearchResultsContext'
import {SortProvider} from './SortContext'
import {FeaturedListingsProvider} from './FeaturedListingsContext'
import {RecommendedListingsProvider} from './RecommendedListingsContext'
import {RecentlyAddedListingsProvider} from './RecentlyAddedListingsContext'
import {CreatorsProvider} from './CreatorsContext'

export function SearchAndFilterProviderStack({children}: PropsWithChildren) {
  return (
    <SearchResultsProvider>
      <SortProvider>
        <FeaturedListingsProvider>
          <RecommendedListingsProvider>
            <RecentlyAddedListingsProvider>
              <CreatorsProvider>
                <FilterProvider>{children}</FilterProvider>
              </CreatorsProvider>
            </RecentlyAddedListingsProvider>
          </RecommendedListingsProvider>
        </FeaturedListingsProvider>
      </SortProvider>
    </SearchResultsProvider>
  )
}

try{ SearchAndFilterProviderStack.displayName ||= 'SearchAndFilterProviderStack' } catch {}